export default [
  {
    header: 'CRM',
    icon: 'UserCheckIcon',
    action: 'default',
    children: [
      {
        title: 'Line User',
        route: 'crm-lineuser-list',
        icon: 'AtSignIcon',
        resource: 'LineUser',
        action: 'read',
      },
      {
        title: 'Member',
        route: 'crm-member',
        icon: 'UserCheckIcon',
        resource: 'Member',
        action: 'read',
      },
      {
        title: 'Media',
        route: 'crm-media',
        icon: 'CameraIcon',
        resource: 'Media',
        action: 'read',
      },
    ],
  },
]
