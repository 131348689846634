/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      // { title: 'Home', route: { name: 'home' }, icon: 'HomeIcon', isBookmarked: true },
      { title: 'User Account', route: { name: 'user-account' }, icon: 'UserIcon', isBookmarked: true },
      { title: 'Line User', route: { name: 'crm-lineuser-list' }, icon: 'AtSignIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      // {
      //   file_name: 'The Trial',
      //   from: 'Sirena Linkert',
      //   icon: require('@/assets/images/icons/pdf.png'),
      //   size: '1.5 mb',
      // },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      // {
      //   img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
      //   name: 'Rena Brant',
      //   email: 'nephrod@preany.co.uk',
      //   time: '21/05/2019',
      // },
    ],
  },
}
/* eslint-enable */
