export default [
  {
    header: 'CMS',
    action: 'default',
  },
  {
    title: 'Home Banner',
    route: 'cms-homebanner',
    icon: 'SquareIcon',
    resource: 'HomeBanner',
    action: 'read',
  },
  {
    title: 'Auction Banner',
    route: 'cms-auctionbanner',
    icon: 'SquareIcon',
    resource: 'AuctionBanner',
    action: 'read',
  },
  {
    title: 'Seller Banner',
    route: 'cms-sellerbanner',
    icon: 'SquareIcon',
    resource: 'SellerBanner',
    action: 'read',
  },
  {
    title: 'Buyer Banner',
    route: 'cms-buyerbanner',
    icon: 'SquareIcon',
    resource: 'BuyerBanner',
    action: 'read',
  },
  {
    title: 'Article Banner',
    route: 'cms-articlebanner',
    icon: 'SquareIcon',
    resource: 'ArticleBanner',
    action: 'read',
  },
  {
    title: 'Contact Banner',
    route: 'cms-contactbanner',
    icon: 'SquareIcon',
    resource: 'ContactBanner',
    action: 'read',
  },
  {
    title: 'Service Banner',
    route: 'cms-servicebanner',
    icon: 'SquareIcon',
    resource: 'ServiceBanner',
    action: 'read',
  },
  {
    title: 'Social',
    route: 'cms-social',
    icon: 'GlobeIcon',
    resource: 'Social',
    action: 'read',
  },
  {
    title: 'Feedback',
    route: 'cms-feedback',
    icon: 'RadioIcon',
    resource: 'Feedback',
    action: 'read',
  },
  {
    title: 'Article',
    route: 'cms-article',
    icon: 'BookIcon',
    resource: 'Article',
    action: 'read',
  },
  {
    title: 'Faq',
    route: 'cms-faq',
    icon: 'HelpCircleIcon',
    resource: 'Faq',
    action: 'read',
  },
  {
    title: 'Content',
    route: 'cms-content',
    icon: 'BookOpenIcon',
    resource: 'Content',
    action: 'read',
  },
  {
    title: 'Property',
    route: 'cms-property',
    icon: 'LayersIcon',
    resource: 'Property',
    action: 'read',
  },
  {
    title: 'Auction',
    route: 'cms-auction',
    icon: 'AwardIcon',
    resource: 'Auction',
    action: 'read',
  },
]
