export default [
  {
    header: 'Tools',
    resource: 'LineUser',
    action: 'read',
  },
  // {
  //   title: 'Line User',
  //   route: 'tools-lineuser-list',
  //   icon: 'AtSignIcon',
  //   resource: 'LineUser',
  //   action: 'read',
  // },
  {
    title: 'LINE Channel',
    route: 'tools-linechannel-list',
    icon: 'SquareIcon',
    resource: 'LineChannel',
    action: 'read',
  },
  {
    title: 'LINE Liff',
    route: 'tools-lineliff-list',
    icon: 'ColumnsIcon',
    resource: 'LineLiff',
    action: 'read',
  },
  {
    title: 'Share Target',
    route: 'tools-sharetarget-list',
    icon: 'Share2Icon',
    resource: 'ShareTarget',
    action: 'read',
  },
  {
    title: 'Share Target Transaction',
    route: 'tools-sharetarget-transaction-list',
    icon: 'ActivityIcon',
    resource: 'ShareTargetTransaction',
    action: 'read',
  },
  {
    title: 'Setting',
    route: 'tools-setting-list',
    icon: 'ToolIcon',
    resource: 'Setting',
    action: 'read',
  },
]
