// import home from './home'
import center from './center'
import tools from './tools'
import cms from './cms'
import crm from './crm'

// Array of sections
export default [
  // ...home,
  ...center,
  ...tools,
  ...cms,
  ...crm,
]
